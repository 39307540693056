<template>
    <div>
        <counter></counter>
    </div>
</template>

<script>
import counter from "@/views/partials/goals/counter.vue";
export default {
    components : {
        counter,
    }
}

</script>